const seeds = {
  "title": "awesomedriver",
  "description": "",
  "groups": [],
  "resources": [
    {
      "title": "Driving school instructors",
      "permalink": "r~driving~school~instructors",
      "description": "",
      "transitions": [
        {
          "title": "Get a list of instructors for this driving school",
          "permalink": "t~driving~school~instructors~get~api~drivingschools~drivingschool~instructors~sessionkey~sessionkey",
          "method": "GET",
          "path": "/api/drivingschools/{drivingSchool}/instructors?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Driving school instructors",
              "permalink": "r~driving~school~instructors"
            }
          }
        },
        {
          "title": "Create instructor for this driving school",
          "permalink": "t~driving~school~instructors~put~api~drivingschools~drivingschool~instructors~sessionkey~sessionkey",
          "method": "PUT",
          "path": "/api/drivingschools/{drivingSchool}/instructors?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Driving school instructors",
              "permalink": "r~driving~school~instructors"
            }
          }
        }
      ]
    },
    {
      "title": "Driving school instructor",
      "permalink": "r~driving~school~instructor",
      "description": "",
      "transitions": [
        {
          "title": "Delete a drivingschool instructor",
          "permalink": "t~driving~school~instructor~delete~api~drivingschools~drivingschool~instructors~instructor~sessionkey~sessionkey",
          "method": "DELETE",
          "path": "/api/drivingschools/{drivingSchool}/instructors/{instructor}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Driving school instructor",
              "permalink": "r~driving~school~instructor"
            }
          }
        },
        {
          "title": "Update a drivingschool instructor",
          "permalink": "t~driving~school~instructor~post~api~drivingschools~drivingschool~instructors~instructor~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/drivingschools/{drivingSchool}/instructors/{instructor}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Driving school instructor",
              "permalink": "r~driving~school~instructor"
            }
          }
        }
      ]
    },
    {
      "title": "Driving schools chamber of commerce API",
      "permalink": "r~driving~schools~chamber~of~commerce~api",
      "description": "",
      "transitions": [
        {
          "title": "Search external",
          "permalink": "t~driving~schools~chamber~of~commerce~api~get~api~drivingschools~external~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/drivingschools/external/?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Driving schools chamber of commerce API",
              "permalink": "r~driving~schools~chamber~of~commerce~api"
            }
          }
        }
      ]
    },
    {
      "title": "Driving school collection",
      "permalink": "r~driving~school~collection",
      "description": "",
      "transitions": [
        {
          "title": "List all driving schools",
          "permalink": "t~driving~school~collection~get~api~drivingschools~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/drivingschools/?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Driving school collection",
              "permalink": "r~driving~school~collection"
            }
          }
        },
        {
          "title": "Create a driving school",
          "permalink": "t~driving~school~collection~put~api~drivingschools~apicsrftoken~apicsrftoken",
          "method": "PUT",
          "path": "/api/drivingschools/?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Driving school collection",
              "permalink": "r~driving~school~collection"
            }
          }
        }
      ]
    },
    {
      "title": "Driving school",
      "permalink": "r~driving~school",
      "description": "",
      "transitions": [
        {
          "title": "Retrieve driving school",
          "permalink": "t~driving~school~get~api~drivingschools~drivingschool~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/drivingschools/{drivingSchool}?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Driving school",
              "permalink": "r~driving~school"
            }
          }
        }
      ]
    },
    {
      "title": "Activate driving school",
      "permalink": "r~activate~driving~school",
      "description": "",
      "transitions": [
        {
          "title": "Activate a driving school",
          "permalink": "t~activate~driving~school~put~api~drivingschools~drivingschoolid~activate~sessionkey~sessionkey",
          "method": "PUT",
          "path": "/api/drivingschools/{drivingSchoolId}/activate?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Activate driving school",
              "permalink": "r~activate~driving~school"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor package request",
      "permalink": "r~instructor~package~request",
      "description": "",
      "transitions": [
        {
          "title": "Request a package",
          "permalink": "t~instructor~package~request~post~api~instructors~instructorid~packages~id~requestpackage~apicsrftoken~apicsrftoken",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/packages/{id}/requestPackage?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Instructor package request",
              "permalink": "r~instructor~package~request"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor test driving lesson request",
      "permalink": "r~instructor~test~driving~lesson~request",
      "description": "",
      "transitions": [
        {
          "title": "Request a test driving lesson",
          "permalink": "t~instructor~test~driving~lesson~request~post~api~instructors~testdrivinglesson~apicsrftoken~apicsrftoken",
          "method": "POST",
          "path": "/api/instructors/testDrivingLesson?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Instructor test driving lesson request",
              "permalink": "r~instructor~test~driving~lesson~request"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor notification",
      "permalink": "r~instructor~notification",
      "description": "",
      "transitions": [
        {
          "title": "Update notification",
          "permalink": "t~instructor~notification~post~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/notifications/{notificationId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor notification",
              "permalink": "r~instructor~notification"
            }
          }
        },
        {
          "title": "Create notification",
          "permalink": "t~instructor~notification~put~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey",
          "method": "PUT",
          "path": "/api/instructors/{instructorId}/notifications/{notificationId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor notification",
              "permalink": "r~instructor~notification"
            }
          }
        },
        {
          "title": "Delete notification",
          "permalink": "t~instructor~notification~delete~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey",
          "method": "DELETE",
          "path": "/api/instructors/{instructorId}/notifications/{notificationId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor notification",
              "permalink": "r~instructor~notification"
            }
          }
        }
      ]
    },
    {
      "title": "Geo",
      "permalink": "r~geo",
      "description": "",
      "transitions": [
        {
          "title": "Retrieve Address",
          "permalink": "t~geo~get~api~geo~address~lat~lat~and~lon~lon~and~sessionkey~sessionkey",
          "method": "GET",
          "path": "/api/geo/address?lat={lat}&lon={lon}&sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Geo",
              "permalink": "r~geo"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor languages",
      "permalink": "r~instructor~languages",
      "description": "",
      "transitions": [
        {
          "title": "Update",
          "permalink": "t~instructor~languages~post~api~instructors~instructorid~languages~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/languages?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor languages",
              "permalink": "r~instructor~languages"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor settings",
      "permalink": "r~instructor~settings",
      "description": "",
      "transitions": [
        {
          "title": "Update",
          "permalink": "t~instructor~settings~post~api~instructors~instructorid~settings~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/settings?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor settings",
              "permalink": "r~instructor~settings"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor profile",
      "permalink": "r~instructor~profile",
      "description": "",
      "transitions": [
        {
          "title": "Update",
          "permalink": "t~instructor~profile~post~api~instructors~instructorid~profile~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/profile?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor profile",
              "permalink": "r~instructor~profile"
            }
          }
        }
      ]
    },
    {
      "title": "User profile",
      "permalink": "r~user~profile",
      "description": "",
      "transitions": [
        {
          "title": "Create",
          "permalink": "t~user~profile~post~api~users~userid~profile~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/users/{userId}/profile?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "User profile",
              "permalink": "r~user~profile"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor",
      "permalink": "r~instructor",
      "description": "",
      "transitions": [
        {
          "title": "Retrieve",
          "permalink": "t~instructor~get~api~instructors~instructorid~sessionkey~sessionkey",
          "method": "GET",
          "path": "/api/instructors/{instructorId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Instructor",
              "permalink": "r~instructor"
            }
          }
        }
      ]
    },
    {
      "title": "Instructor collection",
      "permalink": "r~instructor~collection",
      "description": "",
      "transitions": [
        {
          "title": "List all instructors",
          "permalink": "t~instructor~collection~get~api~instructors~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/instructors/?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Instructor collection",
              "permalink": "r~instructor~collection"
            }
          }
        }
      ]
    },
    {
      "title": "Language collection",
      "permalink": "r~language~collection",
      "description": "",
      "transitions": [
        {
          "title": "List all",
          "permalink": "t~language~collection~get~api~languages~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/languages?apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Language collection",
              "permalink": "r~language~collection"
            }
          }
        }
      ]
    },
    {
      "title": "Package collection",
      "permalink": "r~package~collection",
      "description": "",
      "transitions": [
        {
          "title": "Create package",
          "permalink": "t~package~collection~put~api~instructors~instructorid~packages~sessionkey~sessionkey",
          "method": "PUT",
          "path": "/api/instructors/{instructorId}/packages/?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Package collection",
              "permalink": "r~package~collection"
            }
          }
        }
      ]
    },
    {
      "title": "Package",
      "permalink": "r~package",
      "description": "",
      "transitions": [
        {
          "title": "Update package",
          "permalink": "t~package~post~api~instructors~instructorid~packages~packageid~sessionkey~sessionkey",
          "method": "POST",
          "path": "/api/instructors/{instructorId}/packages/{packageId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Package",
              "permalink": "r~package"
            }
          }
        },
        {
          "title": "Delete package",
          "permalink": "t~package~delete~api~instructors~instructorid~packages~packageid~sessionkey~sessionkey",
          "method": "DELETE",
          "path": "/api/instructors/{instructorId}/packages/{packageId}?sessionKey={sessionKey}",
          "meta": {
            "resource": {
              "title": "Package",
              "permalink": "r~package"
            }
          }
        }
      ]
    },
    {
      "title": "Filter",
      "permalink": "r~filter",
      "description": "",
      "transitions": [
        {
          "title": "Instructors",
          "permalink": "t~filter~get~api~instructors~filters~and~apicsrftoken~apicsrftoken",
          "method": "GET",
          "path": "/api/instructors/?filters&apiCsrfToken={apiCsrfToken}",
          "meta": {
            "resource": {
              "title": "Filter",
              "permalink": "r~filter"
            }
          }
        }
      ]
    }
  ],
  "config": {
    "overrides": {},
    "playground": {
      "enabled": false
    },
    "basePath": "/"
  },
  "uuids": {
    "t~driving~school~instructors~get~api~drivingschools~drivingschool~instructors~sessionkey~sessionkey": "c5CCP5qRwH6zi9qnVoazI",
    "t~driving~school~instructors~put~api~drivingschools~drivingschool~instructors~sessionkey~sessionkey": "ZrUlFSdVMTY0vWyxJ5nLm",
    "t~driving~school~instructor~delete~api~drivingschools~drivingschool~instructors~instructor~sessionkey~sessionkey": "uc4G38oIJRjM2FR8-Allt",
    "t~driving~school~instructor~post~api~drivingschools~drivingschool~instructors~instructor~sessionkey~sessionkey": "OdbBAZLhcaJUhnO6assFi",
    "t~driving~schools~chamber~of~commerce~api~get~api~drivingschools~external~apicsrftoken~apicsrftoken": "3_bBgWy8Q3_cZtqw597Wv",
    "t~driving~school~collection~get~api~drivingschools~apicsrftoken~apicsrftoken": "GiLDkxJ7VBmm7K1clvjTV",
    "t~driving~school~collection~put~api~drivingschools~apicsrftoken~apicsrftoken": "-lk4gZQ3Bl25D-CDfFZ4y",
    "t~driving~school~get~api~drivingschools~drivingschool~apicsrftoken~apicsrftoken": "dZtrpnfrux8W_n9I43ssn",
    "t~activate~driving~school~put~api~drivingschools~drivingschoolid~activate~sessionkey~sessionkey": "_eR-svJJjjru5uNihuWSp",
    "t~instructor~package~request~post~api~instructors~instructorid~packages~id~requestpackage~apicsrftoken~apicsrftoken": "iRrbw5eAvMBE-lq9SWPBz",
    "t~instructor~test~driving~lesson~request~post~api~instructors~testdrivinglesson~apicsrftoken~apicsrftoken": "DzVDin9lRG02LObeqKEWY",
    "t~instructor~notification~post~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey": "oMl0Gj9jFZ1BZYFFtAK1n",
    "t~instructor~notification~put~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey": "s_mK00zI6ubMne0rDYjOz",
    "t~instructor~notification~delete~api~instructors~instructorid~notifications~notificationid~sessionkey~sessionkey": "_2fD7DK1ptVImCsNBIRxg",
    "t~geo~get~api~geo~address~lat~lat~and~lon~lon~and~sessionkey~sessionkey": "l0RMIWyaO3Qw5YSOmus2F",
    "t~instructor~languages~post~api~instructors~instructorid~languages~sessionkey~sessionkey": "Ac5EqgxluMGW93NWWBmIA",
    "t~instructor~settings~post~api~instructors~instructorid~settings~sessionkey~sessionkey": "iSSUIlOK-Q-R3lRtfRyX-",
    "t~instructor~profile~post~api~instructors~instructorid~profile~sessionkey~sessionkey": "M6YRZXvixObtWoPg4S1X8",
    "t~user~profile~post~api~users~userid~profile~sessionkey~sessionkey": "H2n7iczqrJYDz-Z-hE0Mm",
    "t~instructor~get~api~instructors~instructorid~sessionkey~sessionkey": "-Gwl4HcqZQI958ySwt4Q7",
    "t~instructor~collection~get~api~instructors~apicsrftoken~apicsrftoken": "BcALCJgfGFmnn0qy1Y2in",
    "t~language~collection~get~api~languages~apicsrftoken~apicsrftoken": "lyb2em_kLQV66MfeWRLvg",
    "t~package~collection~put~api~instructors~instructorid~packages~sessionkey~sessionkey": "wZLlB7jQ2LI8tlBCG2vT1",
    "t~package~post~api~instructors~instructorid~packages~packageid~sessionkey~sessionkey": "GZ2P8GBr2Ijf0we5gVpxr",
    "t~package~delete~api~instructors~instructorid~packages~packageid~sessionkey~sessionkey": "yvZLmZ9RuRqCAfpfZe0ZG",
    "t~filter~get~api~instructors~filters~and~apicsrftoken~apicsrftoken": "QyJmrWEgXjXZzbKtn0gQd"
  },
  "descriptionToc": []
};
export default seeds;
  